import { computed, ref } from 'vue'

import { type RequestReturn } from '@shopify/shopify-api'

import { type ShopifyGraphQLResponse  } from '@/types/shopify'

import { type Collection, type CollectionByHandle, type Product } from '@/types'

export const useCollection = () => {
  const collection = ref<undefined | Collection>(undefined)

  const fetchCollectionByHandle = async (handle: string, after?: string) => {
    const params = new URLSearchParams({
      handle
    })

    if (after && after.length > 0) {
      params.append('after', after)
    }

    const { data } = await useFetch<RequestReturn<ShopifyGraphQLResponse<CollectionByHandle<Collection>>>>(
      `/api/collections/smart?${params.toString()}`,
      { 
        key: `${handle}-${after || ''}` 
      }
    )

    if (!data.value) return

    collection.value = data.value.body.data.collectionByHandle

    const collectionProducts = products.value.concat(collection.value.products.edges.map((edge) => edge.node))
    
    products.value = collectionProducts
  }

  const fetchCollectionByHandleProductsNextPage = async (handle: string) => {
    if (!productsHasNextPage.value || !productsEndCursor.value) return
    fetchCollectionByHandle(handle, productsEndCursor.value)
  }

  const productsInCollection = computed<Product[]>(() => {
    if (!collection.value) return []
    const products = collection.value.products.edges.map(edge => edge.node) as Product[]

    // Filter out those products that have zero variants, or no image:
    products.map((product: Product) => {
      product.images.edges.length > 0 && product.totalVariants && product.totalVariants > 0
    })

    return products
  })

  const products = ref<Product[]>([])

  const productsHasNextPage = computed<boolean>(() => {
    if (!collection.value) return false
    return collection.value.products.pageInfo.hasNextPage
  })

  const productsHasPreviousPage = computed<boolean>(() => {
    if (!collection.value) return false
    return collection.value.products.pageInfo.hasPreviousPage
  })

  const productsEndCursor = computed<string>(() => {
    if (!collection.value) return ''
    return collection.value.products.pageInfo.endCursor
  })

  const productsStartCursor = computed<string>(() => {
    if (!collection.value) return ''
    return collection.value.products.pageInfo.startCursor
  })

  return {
    collection,
    products,
    productsHasNextPage,
    productsHasPreviousPage,
    productsEndCursor,
    productsStartCursor,
    productsInCollection,
    fetchCollectionByHandle,
    fetchCollectionByHandleProductsNextPage
  }
}